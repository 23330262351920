<template>
  <v-footer app id="footer" class="px-0" :elevation="0">
    <v-container>
      <v-row
        v-if="
          !isTotallyPaid && !isOrderEmpty && !isOrderOpenAndHasNoProductsYet
        "
        justify="center"
      >
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <div v-if="!disablePartialPayment">
            <div class="px-4">
              <v-btn
                @click="submitPartialPayment"
                color="secondary"
                class="px-5 info--text"
                block
                depressed
                x-large
                rounded
              >
                PAGO PARCIAL
              </v-btn>
            </div>
            <v-divider class="my-4" />
          </div>
          <div class="px-4">
            <v-btn
              @click="submitTotalPayment"
              :disabled="hasToDisablePaymentButton"
              :loading="loading"
              color="info"
              class="px-5"
              block
              depressed
              x-large
              rounded
            >
              PAGAR TOTAL
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <div class="px-4">
            <v-btn
              @click="goToRoute"
              :loading="loading"
              color="info"
              class="px-5"
              block
              depressed
              x-large
              rounded
            >
              FINALIZAR
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { getUUID } from "@/utils/uuid.js";
import {
  getPaymentCheckoutUrlOrder,
  getPaymentCheckoutUrlDelivery,
} from "@/plugins/axios";
import thankYouCommon from "@/mixins/firebase/thankYouCommon";
import mobileCommon from "@/mixins/mobile";
import shopUtils from "@/mixins/shopUtils";

export default {
  name: "FooterMenuOrderDetailsPayment",
  mixins: [thankYouCommon, mobileCommon, shopUtils],
  data() {
    return {
      loading: false,
    };
  },

  provide: {
    heading: { align: "center" },
  },
  watch: {
    //aca tengo que ejecutar solo 2 metodos del dispach lo que cambio son las varibles dependiendo el deliverymethod
    getDeliveryMethod() {
      this.obtainDeliveryMethod();
    },
  },
  mounted() {
    this.obtainDeliveryMethod();
    // const orderUuid = window.localStorage.getItem("orderUuid");
    // const uuid = window.localStorage.getItem("multipaymentUuid");
    // console.log("HOLA", orderUuid, uuid);
    // if (orderUuid && orderUuid !== "null") {
    //   this.$store.dispatch("order/updateFailurePayment", {
    //     orderUuid,
    //   });
    // } else if (uuid && uuid !== "null") {
    //   this.$store.dispatch("order/updateFailurePayment", {
    //     uuid,
    //   });
    // }
  },

  computed: {
    getDeliveryMethod() {
      return this.$store.getters["deliveryCart/getDeliveryMethod"];
    },
    isKioskModeEnabled() {
      return this.$store.state.menu.profile.isKioskModeEnabled;
    },
    hasAnyPaymentMethodConfigured() {
      return (
        this.$store.state.menu.profile.isMercadoPagoEnabled ||
        this.$store.state.menu.profile.isModoEnabled ||
        (this.$store.state.menu.profile.isOtherPaymentWayEnabled &&
          this.isKioskModeEnabled)
      );
    },
    hasToDisablePaymentButton() {
      return (this.$route.meta.isDelivery &&
        !this.isDeliveryCartInformationFilled) ||
        !this.isValidAmount ||
        !this.hasAnyPaymentMethodConfigured
        ? true
        : false;
    },
    isDeliveryCartInformationFilled() {
      if (this.isKioskModeEnabled) {
        return Boolean(this.$store.state.deliveryCart.shippingInformation.name);
      } else if (this.$store.state.menu.profile.isWebOrdersEnabled) {
        if (this.$store.state.menu.profile.operationMode == 2) {
          return Boolean(
            //Este es modo 2!!!!!!!
            this.$store.state.deliveryCart.shippingInformation.email &&
              this.validateEmail(
                this.$store.state.deliveryCart.shippingInformation.email
              )
          );
        } else if (this.$store.state.menu.profile.operationMode == 1) {
          return Boolean(
            //Este es modo 1
            this.$store.state.deliveryCart.shippingInformation.email &&
              this.validateEmail(
                this.$store.state.deliveryCart.shippingInformation.email
              ) &&
              ((this.$store.state.deliveryCart.shippingInformation.address
                ?.length > 0 &&
                this.$store.getters["deliveryCart/getDeliveryMethod"] ==
                  "delivery") ||
                this.$store.getters["deliveryCart/getDeliveryMethod"] ==
                  "pickup") &&
              this.$store.state.deliveryCart.shippingInformation.name?.length >
                0 &&
              this.$store.state.deliveryCart.shippingInformation.phone?.length >
                0
          );
        } else
          return Boolean(
            //Este es modo 2!!!!!!!
            this.$store.state.deliveryCart.shippingInformation.email &&
              this.validateEmail(
                this.$store.state.deliveryCart.shippingInformation.email
              )
          );
      } else
        return Boolean(
          //Este es modo 2!!!!!!!
          this.$store.state.deliveryCart.shippingInformation.email &&
            this.validateEmail(
              this.$store.state.deliveryCart.shippingInformation.email
            )
        );
    },
    disablePartialPayment() {
      if (this.$route.meta.isDelivery) {
        return true;
      }
      return false;
    },
    remainingPaymentAmount() {
      return this.$store.getters["order/remainingPaymentAmount"];
    },
    inProgressSortedExtendedProducts() {
      return this.$store.getters["order/inProgressSortedExtendedProducts"];
    },
    isTipInProgress() {
      return this.$store.getters["order/currentTip"]?.uuid;
    },
    getOrderUuid() {
      return this.$store.getters["order/uuid"];
    },
  },

  methods: {
    obtainDeliveryMethod() {
      let minimumDeliveryTimeInMinutes =
        this.$store.state.menu.profile.minimumDeliveryTimeInMinutes;
      let maximumDeliveryTimeInMinutes =
        this.$store.state.menu.profile.maximumDeliveryTimeInMinutes;
      let minimumPickupTimeInMinutes =
        this.$store.state.menu.profile.minimumPickupTimeInMinutes;
      let maximumPickupTimeInMinutes =
        this.$store.state.menu.profile.maximumPickupTimeInMinutes;

      //Selecciono en que modo está
      if (this.$store.state.deliveryCart.deliveryMethod === "delivery") {
        //Pregunto si esta habilitado el tiempo de entrega o no.
        if (this.$store.state.menu.profile.isDeliveryTimeEnabled) {
          this.$store.dispatch(
            "deliveryCart/setDeliveryOrPickupMinimumTime",
            minimumDeliveryTimeInMinutes
          );
          this.$store.dispatch(
            "deliveryCart/setDeliveryOrPickupMaximumTime",
            maximumDeliveryTimeInMinutes
          );
        }
      }
      if (this.$store.state.deliveryCart.deliveryMethod === "pickup") {
        //Pregunto si esta habilitado el tiempo de entrega o no.
        if (this.$store.state.menu.profile.isPickupTimeEnabled) {
          this.$store.dispatch(
            "deliveryCart/setDeliveryOrPickupMinimumTime",
            minimumPickupTimeInMinutes
          );
          this.$store.dispatch(
            "deliveryCart/setDeliveryOrPickupMaximumTime",
            maximumPickupTimeInMinutes
          );
        }
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    goToRoute() {
      const { commerceId, table } = this.$route.query;
      this.$router.push({
        name: "MenuOrder",
        query: { commerceId, table },
      });
    },

    redirectToPaymentCheckout(url) {
      window.location.href = url;
    },

    async submitTotalPayment() {
      const isDelivery = this.$route.meta.isDelivery;
      let isKioskModeEnabled = false;
      try {
        isKioskModeEnabled = this.$store.state.menu.profile.isKioskModeEnabled;
        if (!this.validatePickupPointSelection()) return;
      } catch {}
      if (isKioskModeEnabled) {
        this.$store.dispatch("deliveryCart/bKioskAmount");
      } else if (isDelivery) {
        this.submitTotalPaymentDeliveryCart();
      } else {
        this.submitTotalPaymentOrder();
      }
    },
    async submitTotalPaymentDeliveryCart() {
      this.loading = true;

      const payload = {
        table: window.localStorage.getItem("deliveryUuid"),
        externalReference: window.localStorage.getItem("deliveryUuid"),
        shopName: this.$store.state.menu.profile.shopName,
        shopCode: this.$route.query.commerceId,
        paymentMethod: this.$store.state.deliveryCart.paymentMethod,
        deliveryMethod: this.$store.state.deliveryCart.deliveryMethod,
        userEmail: this.$store.state.deliveryCart.shippingInformation.email,
        total: this.$store.getters["deliveryCart/totalPrice"],
        payloadData: JSON.stringify(this.$store.state.deliveryCart),
        isMobile: this.isMobile(),
      };

      await getPaymentCheckoutUrlDelivery(payload)
        .then((response) => {
          if (response.data?.responseCode === 0) {
            // console.log("response", response);
            // this.$store.dispatch(
            //   "deliveryCart/updateUuid",
            //   response.data?.externalReference
            // );
            this.redirectToPaymentCheckout(response.data?.paymentUrl);
          } else {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              msg: response.data?.responseMessage,
              success: false,
            });
          }
        })
        .catch(() => {
          this.$store.commit("snackbar/OPEN_SNACKBAR", {
            msg: "Hubo un error, intenta de nuevo.",
            success: false,
          });
        });

      this.loading = false;
    },

    async submitTotalPaymentOrder() {
      this.loading = true;
      const payload = {
        table: this.$route.query.table,
        shopName: this.$store.state.menu.profile.shopName,
        shopCode: this.$route.query.commerceId,
        total: this.remainingPaymentAmount,
        isPartial: false,
      };

      // let isAnotherTotalPaymentInProgress = false;
      // const orderUuid = window.localStorage.getItem("orderUuid");
      // if (this.getOrderUuid && orderUuid && this.getOrderUuid === orderUuid) {
      //   this.$store.dispatch("order/setOrderUuid", getUUID());
      // } else if (this.getOrderUuid) {
      //   isAnotherTotalPaymentInProgress = true;
      // } else {
      //   this.$store.dispatch("order/setOrderUuid", getUUID());
      // }

      if (
        !this.inProgressSortedExtendedProducts[0] &&
        !this.isTipInProgress &&
        !this.getOrderUuid
      ) {
        payload.uuid = getUUID();
        this.$store.dispatch("order/setOrderUuid", payload.uuid);
        await getPaymentCheckoutUrlOrder(payload)
          .then((response) => {
            if (response.data?.responseCode === 0) {
              this.redirectToPaymentCheckout(response.data?.paymentUrl);
            } else {
              this.$store.commit("snackbar/OPEN_SNACKBAR", {
                msg: response.data?.responseMessage,
                success: false,
              });
            }
          })
          .catch(() => {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              msg: "Hubo un error, intenta de nuevo.",
              success: false,
            });
          });
      } else {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          msg: "No se puede hacer un pago total, mientras hayan pagos en progreso. Por favor comunicate con el mozo para poder pagar tu cuenta",
          success: false,
        });
      }

      this.loading = false;
    },

    submitPartialPayment() {
      if (!this.getOrderUuid) {
        this.$router.push({
          path: "/menu/order/details/payment/partial",
          query: this.$route.query,
        });
      } else {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          msg: "No se puede hacer un pago parcial, mientras haya un pago total en progreso.",
          success: false,
        });
      }
    },
  },
};
</script>
<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
